import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { isDesktop, isMobileOnly, isTablet } from "react-device-detect";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { base_url, postHeader } from "../../../../Helpers/request";
import axios from "axios";
import { toast } from "react-toastify";

// import S1 from "../../../../Assets/images/sl1.jpg";

let sliderData = [
  // {
  //  img: require('../../../../Assets/images/banner1.png'),
  //  mobimg: require('../../../../Assets/images/1-mob.jpg'),
  //   imgUrl: "/jewelry/fine-jewelry",
  //   bannerHeading: "Fine Jewellery ",
  //   bannerText: "Explore the latest Fine Jewellery",
  //   bannerButton:"SHOP Fine Jewellery",
  // },
  // {
  //  img:  require('../../../../Assets/images/banner2.png'),
  //  mobimg:   require('../../../../Assets/images/2-mob.jpg'),
  //   imgUrl: "/ringsettings",
  //   bannerHeading: "Engagement Rings",
  //   bannerText: "Discover our unique Engagement Rings collection",
  //   bannerButton:"SHOP Engagement RINGS",
  // },
  // {
  //  img: require('../../../../Assets/images/banner3.png'),
  //  mobimg: require('../../../../Assets/images/3-mob.jpg'),
  //   imgUrl: "jewelry/wedding-rings",
  //   bannerHeading: "Perfect Symbol Of Love",
  //   bannerText: "Explore the latest Wedding collection",
  //   bannerButton:"SHOP Wedding Rings",
  // }
];

const MacauSlider = () => {
  const history = useHistory();
  const [banner, setBanner] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    //-----------------------------------Banner
    setLoading(true);
    axios
      .get(base_url + `/home/slider`, {
        headers: postHeader,
      })
      .then((response) => {
        if (response.data.status == 1) {
          setLoading(false);
          setBanner(response.data.data);
          localStorage.setItem(
            "bw-bannerdata",
            JSON.stringify(response.data.data)
          );
        } else {
          toast.error(response.message, { autoClose: 3000 });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  var settings = {
    dots: true,
    infinite: true,
    arrows: true,
    speed: 300,
    accessibility: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          dots: true,
        },
      },
    ],
  };

  return (
    <>
      <Slider
        {...settings}
        className="overflow-hidden bannerSliderHome macau_banner_slider ggggggggg"
      >
        {banner?.map((val, index) => (
          <div key={index}>
            <Link to={val?.btn_link}>
              {/* <img src={val?.img} alt="slider" className='img-fluid' /> */}
              <div
                className="bg_banner"
                style={{
                  backgroundImage: `url(${
                    isMobileOnly ? val?.mobile_image : val?.image
                  })`,
                }}
              >
                <Container className="showcase-container">
                  <Row>
                    <Col lg={{span:6, offset:6}} md={{span:6, offset:6}} sm={12} className="d-flex justify-content-center">
                      <div className="ny_banner_content">
                        <h1>{val.title}</h1>
                        <p>{val.short_text}</p>
                        <Button 
                          variant="dark"
                          onClick={() => history.push(val.btn_link)}
                        >
                          {val.btn_text}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Link>
          </div>
        ))}
      </Slider>

      {/* <img src={S1} /> */}
    </>
  );
};

export default MacauSlider;
