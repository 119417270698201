import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import StylishBorder from './../../../../Assets/images/New York/Slider/line-black.png';
import Engring from '../../../../Assets/images/jewelbox/1.png'
import wedband from '../../../../Assets/images/jewelbox/2.png'
import Neck from '../../../../Assets/images/jewelbox/3.png'
import earr from '../../../../Assets/images/jewelbox/4.png'
import brace from '../../../../Assets/images/jewelbox/5.png'
const ExploreOurCollection = () => {
    const collectiondata = [
        {
            title: 'Engagement Rings',
            desc: 'Select your perfect Engagement Ring with diamond. Choose from wide range of designer rings.',
            img: Engring,
            url:'/ringsettings'
        },
        {
            title: 'Wedding RINGS',
            desc: 'Find the perfect symbol of your love. Discover eternity diamond rings, classic bands, and unique styles.',
            img: wedband,
            url:'/jewelry/wedding-rings'
        },
        {
            title: 'Necklaces',
            desc: 'Find the perfect length for every occasion with classic pearl strands, diamond elegance,colorful gemstones and more!',
            img: Neck,
            url:'/jewelry/necklaces'
        },
        {
            title: 'Earrings',
            desc: 'Explore our exciting varieties of stylish earrings from short and sweet to long and dramatic.',
            img: earr,
            url:'/jewelry/earrings'
        },
        {
            title: 'Bracelets',
            desc: 'Adorn your wrist with perfection and complement every style with feminine fashion, bangle, and diamond bracelets.',
            img: brace,
            url: '/jewelry/bracelets'
        },
    ]
    return (
        <section className='ExploreOurCollection'>
            <Container>
                <div className='title-box text-center mb-5'>
                    <h2>Explore Our Collection</h2>
                    <img src={StylishBorder} alt='' />
                </div>
                {collectiondata.map((data) => (
                    <Link to={data.url} className='ExploreOurCollection_box'>
                        <div className='ExploreOurCollection_innerbox'>
                            <div className='ExploreOurCollection_box_text'>
                                <h3>{data.title}</h3>
                                <p>{data.desc}</p>
                            </div>
                            <Image src={data.img} alt='Engagement Rings' />
                        </div>
                    </Link>
                ))}
            </Container>
        </section>
    )
}

export default ExploreOurCollection