import React from 'react'
import { Link } from 'react-router-dom';
import StylishBorder from './../../../../Assets/images/New York/Slider/line-black.png';

export const InstagramFeed = () => {
  return (
    <>
        <section className='instafeed pt-5'>
            <div className='container'>
                <div className='title-box text-center mb-3'>
                    <h2>GET A TOUR OF OUR SOCIAL MEDIA</h2>
                    <img src={StylishBorder} alt='' />
                </div>

                {/* <p className='text-center'>Follow <a target="_blank" href="#" className='text-dark'>@lakeHillier</a> on Instagram to get inspired</p>    */}

                {/* <div className="elfsight-app-368122dd-eb07-4a5c-b0a6-014ed3804162"></div> */}
            </div>
            <iframe src="https://instagram.demobw.live/jewelbox/" frameborder="0" width="100%" class="insta-iframe"></iframe>
        </section>
    </>
  )
}
