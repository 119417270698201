import React from "react";
import StylishBorder from "./../../../../Assets/images/New York/Slider/line-black.png";
import Star from "./../../../../Assets/images/New York/Slider/str.svg";
import GoogleReview from "./../../../../Assets/images/New York/Slider/google-reviews.jpg";
import YelpReview from "./../../../../Assets/images/New York/Slider/yelp-reviews.jpg";
import IvouchReview from "./../../../../Assets/images/New York/ivouch-reviews.jpg";
import ReactOwlCarousel from "react-owl-carousel";
import { Link } from "react-router-dom";
import { ArrowBackIos, ArrowRightAlt } from "@material-ui/icons";
import { BsArrowLeft } from "react-icons/bs";
import { BsArrowRight } from "react-icons/bs";
import { FaQuoteLeft } from "react-icons/fa";
import { Tab, Tabs } from "react-bootstrap";
import { Rating } from "@mui/material";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
const WhatPeopleSay = () => {
  return (
    <>
      <section className="testimonials">
        <div className="container">
          <div className="title-box text-center pb-4">
            <h2>WHAT PEOPLE SAY</h2>
            <img src={StylishBorder} alt="StylishBorder" />
          </div>
          <Row>
            {/* <Tabs
              defaultActiveKey="google"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="google" title=""> */}
            <Col lg={12} sm={12} md={12}>
              <div className="slider-wrapper">
                <div className="google-review review-block">
                  <div className="header text-center">
                    <img src={GoogleReview} alt="" />
                  </div>
                  <div className="body text-center position-relative">
                    <div className="testimonial-icon">
                      <FaQuoteLeft />
                    </div>
                    <ReactOwlCarousel
                      className="owl-main owl-theme"
                      nav={true}
                      dots={true}
                      // navText={[ArrowBackIos, ArrowRightAlt]}
                      navText={[BsArrowLeft, BsArrowRight]}
                      items={1}
                      loop
                    >
                      <div className="item">
                        <p>
                          <span>Best jeweler in Malta, 5/5 100% recommend. The whole team was friendly and very patient. Warren is a master craftsman and he understood our needs perfectly and made us rings to cherish for life. Will definitely come back for our next special occasion. Thank you!</span>
                          {/* <a
                            target="_blank"
                            href="https://www.google.com/search?q=Jewelboxmalta&oq=Jewelboxmalta&gs_lcrp=E#lrd=0x130e45c35b5390ed:0xed6f7bac1c164b1,1,,,,"
                          >
                            Read More
                          </a> */}
                        </p>
                        <div className="aj_rev_RatingStar">
                          <Rating
                            name="1.1"
                            defaultValue={5}
                            precision={1.1}
                            readOnly
                          />
                        </div>
                        <h3>
                          <a
                            target="_blank"
                            href="https://www.google.com/search?q=Jewelboxmalta&oq=Jewelboxmalta&gs_lcrp=E#lrd=0x130e45c35b5390ed:0xed6f7bac1c164b1,1,,,,"
                          >
                            Andrea Attard
                          </a>
                        </h3>
                      </div>

                      <div className="item">
                        <p>
                          <span>I had a puzzle ring which I need to size up but I wanted it to still be taken apart and put back together everyone said it’s impossible and 1 person quoted me a insane price for 3 sizes I took it to them as a last shot and they did it within 1 hour for a very good price and made it like new I cannot recommend them more they are very very very good quality and lovely people to work with</span>
                          {/* <a
                            target="_blank"
                            href="https://www.google.com/search?q=Jewelboxmalta&oq=Jewelboxmalta&gs_lcrp=E#lrd=0x130e45c35b5390ed:0xed6f7bac1c164b1,1,,,,"
                          >
                            Read More
                          </a> */}
                        </p>
                        <div className="aj_rev_RatingStar">
                          <Rating
                            name="5"
                            defaultValue={5}
                            precision={5}
                            readOnly
                          />
                        </div>
                        <h3>
                          <a
                            target="_blank"
                            to="https://www.google.com/search?q=Jewelboxmalta&oq=Jewelboxmalta&gs_lcrp=E#lrd=0x130e45c35b5390ed:0xed6f7bac1c164b1,1"
                          >
                            Matthew Formosa
                          </a>
                        </h3>
                      </div>

                      <div className="item">
                        <p>
                          <span>Lovely shop and lovely staff. We got our weddings rings here. Couldn't have asked for a better service</span>
                          {/* <a
                            target="_blank"
                            href="https://www.google.com/search?q=Jewelboxmalta&oq=Jewelboxmalta&gs_lcrp=E#lrd=0x130e45c35b5390ed:0xed6f7bac1c164b1,1,,,,

                            "
                          >
                            Read More
                          </a> */}
                        </p>
                        <div className="aj_rev_RatingStar">
                          <Rating
                            name="1.3"
                            defaultValue={5}
                            precision={1.3}
                            readOnly
                          />
                        </div>
                        <h3>
                          <a
                            target="_blank"
                            href="https://www.google.com/search?q=Jewelboxmalta&oq=Jewelboxmalta&gs_lcrp=E#lrd=0x130e45c35b5390ed:0xed6f7bac1c164b1,1,,,,
                            "
                          >
                            Douglas Owen
                          </a>
                        </h3>
                      </div>

                      <div className="item">
                        <p>
                          <span>Very good service! Recommended 100%!</span>
                          {/* <a
                            target="_blank"
                            href="https://www.google.com/search?q=Jewelboxmalta&oq=Jewelboxmalta&gs_lcrp=E#lrd=0x130e45c35b5390ed:0xed6f7bac1c164b1,1,,,,

                            "
                          >
                            Read More
                          </a> */}
                        </p>
                        <div className="aj_rev_RatingStar">
                          <Rating
                            name="1.4"
                            defaultValue={5}
                            precision={1.4}
                            readOnly
                          />
                        </div>
                        <h3>
                          <a target="_blank" href="#">Nina B.</a>
                        </h3>
                      </div>
                      
                      <div className="item">
                        <p>
                          <span>Good service and great prices</span>
                          {/* <a
                            target="_blank"
                            href="https://www.google.com/search?q=Jewelboxmalta&oq=Jewelboxmalta&gs_lcrp=E#lrd=0x130e45c35b5390ed:0xed6f7bac1c164b1,1,,,,

                            "
                          >
                            Read More
                          </a> */}
                        </p>
                        <div className="aj_rev_RatingStar">
                          <Rating
                            name="1.4"
                            defaultValue={5}
                            precision={1.4}
                            readOnly
                          />
                        </div>
                        <h3>
                          <a target="_blank" href="#">Letterio Garufi</a>
                        </h3>
                      </div>
                    </ReactOwlCarousel>
                  </div>
                </div>
                {/* </Tab> */}

                {/* </Tabs> */}
              </div>
            </Col>

            {/* <Col lg={6} sm={12} md={6}> 

<div className="slider-wrapper ny_yel">
            
                <div className="google-review review-block">
                  <div className="header text-center">
                    <img src={YelpReview} alt="YelpReview" />
                  </div>
                  <div className="body text-center position-relative">
                    <div className="testimonial-icon">
                      <FaQuoteLeft />
                    </div>
                    <ReactOwlCarousel
                      className="owl-main owl-theme"
                      nav={true}
                      dots={true}
                      // navText={[ArrowBackIos, ArrowRightAlt]}
                      navText={[BsArrowLeft, BsArrowRight]}
                      
                           

                      items={1}
                      loop
                    >
                      <div className="item">
                        <p>
                          <span>
                            Excellent jewellery selection, customer service and
                            price. Purchased diamond wedding bands a decade ago
                            and have been pleased with the quality. Would highly
                            recommend for all jewelery needs including re-sizing
                            and custom orders. Will certainly repeat business.
                          </span>
                          <a
                            target="_blank"
                            href="/"
                          >
                            Read More
                          </a>
                        </p>
                        <div className="aj_rev_RatingStar">
                          <Rating name="1.1" defaultValue={5} precision={1.1} readOnly />
                        </div>
                        <h3>
                          <a
                            target="_blank"
                            href="/"
                          >
                            J. Jones
                          </a>
                        </h3>
                      </div>

                      <div className="item">
                        <p>
                          <span>
                            I have been going to Magnolia Jewelers for 5 years now.
                            They are very professional. I've had many repairs
                            done by them. Today they put my new watch band on my
                            watch for free. I will be returning to them soon.
                            Very courteous.
                          </span>
                          <a
                            target="_blank"
                            href="/"
                          >
                            Read More
                          </a>
                        </p>
                        <div className="aj_rev_RatingStar">
                          <Rating name="5" defaultValue={5} precision={5} readOnly />
                        </div>
                        <h3>
                          <a
                            target="_blank"
                            to="/"
                          >
                            Theresa Resch
                          </a>
                        </h3>
                      </div>

                      <div className="item">
                        <p>
                          <span>
                            The best place to buy jewellery. I've been coming to
                            this place for over 20 years and they always have
                            the best prices and quality. Wedding rugs shopping?
                            Go here fellas!
                          </span>
                          <a
                            target="_blank"
                            href="/"
                          >
                            Read More
                          </a>
                        </p>
                        <div className="aj_rev_RatingStar">
                          <Rating name="1.3" defaultValue={5} precision={1.3} readOnly />
                        </div>
                        <h3>
                          <a
                            target="_blank"
                            href="/"
                          >
                            Cammron Hollimon
                          </a>
                        </h3>
                      </div>

                      <div className="item">
                        <p>
                          <span>
                            The family oriented staff and reasonable pricing is
                            the reason it's our "go to" place for fine
                            jewellery....even on not special occasions!!
                          </span>
                          <a
                            target="_blank"
                            href="/"
                          >
                            Read More
                          </a>
                        </p>
                        <div className="aj_rev_RatingStar">
                          <Rating name="1.4" defaultValue={5} precision={1.4} readOnly />
                        </div>
                        <h3>
                          <a
                            target="_blank"
                            href="#"
                          >
                            Estella Cohen
                          </a>
                        </h3>
                      </div>
                    </ReactOwlCarousel>
                  </div>
                </div>
          
          </div>
</Col> */}
            {/* </Tab>

</Tabs> */}
          </Row>
        </div>
      </section>
    </>
  );
};

export default WhatPeopleSay;
