import { ArrowBackIos, ArrowRightAlt } from '@material-ui/icons';
import React from 'react';
import ReactOwlCarousel from 'react-owl-carousel';
import StylishBorder from './../../../../Assets/images/New York/Slider/line-black.png';
import Asscher from './../../../../Assets/images/New York/Slider/Asscher.png'; 
import Cushion from './../../../../Assets/images/New York/Slider/Cushion.png'; 
import Emerald from './../../../../Assets/images/New York/Slider/Emerald.png'; 
import Heart from './../../../../Assets/images/New York/Slider/Heart.png'; 
import Marquise from './../../../../Assets/images/New York/Slider/Marquise.png'; 
import Oval from './../../../../Assets/images/New York/Slider/Oval.png'; 
import Pear from './../../../../Assets/images/New York/Slider/Pear.png';
import Princess from './../../../../Assets/images/New York/Slider/Princess.png';
import Radiant from './../../../../Assets/images/New York/Slider/Radiant.png';
import Round from './../../../../Assets/images/New York/Slider/Round.png';
import { Link } from 'react-router-dom';
import { BsArrowRight } from "react-icons/bs";
import { Col, Row } from 'react-bootstrap';

export const ShopDiamondShape = () => {
    return (
        <>
            {/* <section className='shop_diamond_shape my-5 pb-lg-3 pb-0'> */}
            <div className='shop_diamond_shape fgggggggggg mb-5 pb-lg-4 pb-0'>
                <div className='container'>
                    <div className='title-box text-center mb-4'>
                        <h2>SHOP DIAMONDS BY SHAPE</h2>
                        <img src={StylishBorder} alt='' />
                    </div>
                    <Row>
                        <Col sm={12} md={12} lg={12} className='p-0'>
                        <ReactOwlCarousel className="owl-main owl-theme" 
                    nav
                    navText={['', '']}
                    dots={false} 
                    margin={0} 
                    items={4}
                    responsive={
                        {0: {
                                center: true,
                                items: 3,
                                nav: false
                            },
                        450: {
                                center: true,
                                items: 4,
                                nav: false
                                },
                        992: {
                                center: true,
                                items: 4,
                                nav: false
                                },
                        1024: {
                                center: false,
                                items: 4,
                                nav: true
                                }
                            }
                    }  
                    loop>
                        <div className='gb_shap_item_margin'>
                        <div className='item text-center'>
                            <Link to="diamonds/shape=Asscher"><img src={Asscher} alt='' /></Link>

                            <div className='content position-relative'> 
                                <Link to="diamonds/shape=Asscher">Asscher</Link>
                                <p className='m-0'>This unique shape is a square emerald, with linear facets.</p>
                            </div>
                        </div>
                        </div>
                    
                        <div className='gb_shap_item_margin'>
                        <div className='item text-center'>
                            <Link to="diamonds/shape=Cushion"><img src={Cushion} alt='' /></Link>

                            <div className='content position-relative'> 
                                <Link to="diamonds/shape=Cushion">Cushion</Link>
                                <p className='m-0'>This unique shape is a square emerald, with linear facets.</p>
                            </div>
                        </div>
</div>
<div className='gb_shap_item_margin'>
                        <div className='item text-center'>
                            <Link to="diamonds/shape=Emerald"><img src={Emerald} alt='' /></Link>

                            <div className='content position-relative'> 
                                <Link to="diamonds/shape=Emerald">Emerald</Link>
                                <p className='m-0'>This unique shape is a square emerald, with linear facets.</p>
                            </div>
                        </div>
                        </div>
                        <div className='gb_shap_item_margin'>
                        <div className='item text-center'>
                            <Link to="diamonds/shape=Heart"><img src={Heart} alt='' /></Link>

                            <div className='content position-relative'> 
                                <Link to="diamonds/shape=Heart">Heart</Link>
                                <p className='m-0'>This unique shape is a square emerald, with linear facets.</p>
                            </div>
                        </div>
                        </div>
                        <div className='gb_shap_item_margin'>

                        <div className='item text-center'>
                            <Link to="diamonds/shape=Marquise"><img src={Marquise} alt='' /></Link>

                            <div className='content position-relative'> 
                                <Link to="diamonds/shape=Marquise">Marquise</Link>
                                <p className='m-0'>This unique shape is a square emerald, with linear facets.</p>
                            </div>
                        </div>
                        </div>
                        <div className='gb_shap_item_margin'>
                        <div className='item text-center'>
                            <Link to="diamonds/shape=Oval"><img src={Oval} alt='' /></Link>

                            <div className='content position-relative'> 
                                <Link to="diamonds/shape=Oval">Oval</Link>
                                <p className='m-0'>This unique shape is a square emerald, with linear facets.</p>
                            </div>
                        </div>
</div>
<div className='gb_shap_item_margin'>
                        <div className='item text-center'>
                            <Link to="diamonds/shape=Pear"><img src={Pear} alt='' /></Link>

                            <div className='content position-relative'> 
                                <Link to="diamonds/shape=Pear">Pear</Link>
                                <p className='m-0'>This unique shape is a square emerald, with linear facets.</p>
                            </div>
                        </div>
                        </div>
                        <div className='gb_shap_item_margin'>

                        <div className='item text-center'>
                            <Link to="diamonds/shape=Princess"><img src={Princess} alt='' /></Link>

                            <div className='content position-relative'> 
                                <Link to="diamonds/shape=Princess">Princess</Link>
                                <p className='m-0'>This unique shape is a square emerald, with linear facets.</p>
                            </div>
                        </div>
                        </div>
                        <div className='gb_shap_item_margin'>
                        <div className='item text-center'>
                            <Link to="diamonds/shape=Radiant"><img src={Radiant} alt='' /></Link>

                            <div className='content position-relative'> 
                                <Link to="diamonds/shape=Radiant">Radiant</Link>
                                <p className='m-0'>This unique shape is a square emerald, with linear facets.</p>
                            </div>
                        </div>
</div>
<div className='gb_shap_item_margin'>
                        <div className='item text-center'>
                            <Link to="diamonds/shape=Round"><img src={Round} alt='' /></Link>

                            <div className='content position-relative'> 
                                <Link to="diamonds/shape=Round">Round</Link>
                                <p className='m-0'>This unique shape is a square emerald, with linear facets.</p>
                            </div>
                        </div>
                        </div>

                    </ReactOwlCarousel>
                        </Col>
                    
                    </Row>

                   
                </div>
            </div>
        </>
    )
}
